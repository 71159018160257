/*---------------------------
	Fonts
----------------------------*/
@import url('https://fonts.googleapis.com/css2?family=Itim&family=Outfit:wght@300;400;500;600;700;800;900&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300..700&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');




// fonts
$base-font-size: 15;
$base-font: 'poppins';
$heading-font: 'Quicksand';



// color
$send-comment-hover : #009540;
$dark-gray: #232323;
$body-color: #687693;
$white: #fff;
$light: #8188a9;
$black: #000;
$small-black: #333;
$cyan: #848892;

$theme-primary-color: #f29100;
$theme-primary-color-s2: #009540;
$body-bg-color: #fff;
$section-bg-color: #F7F6F1;
$section-bg-color2: #F3F1EA;
$text-color: #6e6e6e;
$text-light-color: #676767;
$heading-color: $dark-gray;
$border-color: #ebebeb;
$border-color-s2: #d8e0f1;
